// import { addAlert } from '../../../../utilities/helpers'

import { formatDate, formatTime, formatCurrency } from '@smarttransit/common'
import { updateQueryParams, maskPhoneNumber } from '@smarttransit/common-client'
import moment from 'moment-timezone'
import { addAlert } from '../../../../utilities/helpers'

import {
  findBulkPassengers,
  findTotalBulkPassengers,
  sendPassengersBulkNotification
} from '../../../../services/passengers-service'

// eslint-disable-next-line no-unused-vars
let _cachedComponentData = false

export default {
  props: {
    signedInUser: Object,
    countries: Array,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      name: 'passengers-bulk-notifications',
      apiInProgress: false,
      sendingNotification: false,
      extraFiltersModal: false,
      balanceFilterOperator: '=',
      balanceFilterValue: '',
      ticketsFilterOperator: '=',
      ticketsFilterValue: '',
      daysOfNoTripsFilterValue: '',
      smsContent: '',
      filteredPassengers: [],
      selectedCountry: 'GHA',
      selectedLabel: 'None selected',
      searchKeywords: '',
      individualSelection: false,
      dateCategory: 'dateCreated',
      dateFrom: null,
      dateTo: null,
      dateFromMenu: null,
      dateToMenu: null,
      order: 'dateUpdated DESC NULL LAST',
      orderItems: [
        { text: 'Date Updated Latest', value: 'dateUpdated DESC NULL LAST' },
        { text: 'Date Updated Earliest', value: 'dateUpdated ASC' },
        { text: 'Date Created Latest', value: 'dateCreated DESC' },
        { text: 'Date Created Earliest', value: 'dateCreated ASC' },
        { text: 'Wallet Balance Highest', value: 'balance DESC' },
        { text: 'Wallet Balance Lowest', value: 'balance ASC' }
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        pageTotal: 0,
        totalItems: null
      }
    }
  },
  computed: {
    extraFiltersTags () {
      const tags = []

      if (this.balanceFilterValue) {
        const parsedBalanceFilterValue = Math.round(Number(this.balanceFilterValue) * 100)
        tags.push(`Balance ${this.balanceFilterOperator} ${formatCurrency(parsedBalanceFilterValue)}`)
      }

      if (this.ticketsFilterValue) {
        tags.push(`Tickets ${this.ticketsFilterOperator} ${this.ticketsFilterValue}`)
      }

      if (this.daysOfNoTripsFilterValue) {
        tags.push(`Days since last trip: ${this.daysOfNoTripsFilterValue}`)
      }

      if (this.dateCategory && (this.dateFrom || this.dateTo)) {
        tags.push(`${this.dateCategory} ${[this.dateFrom, this.dateTo].filter(Boolean).map((o) => formatDate(o)).join(' - ')}`)
      }

      return tags
    },
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }
      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }
      return ''
    }
  },
  watch: {
    filteredPassengers (newVal) {
      if (this.individualSelection) {
        this.selectedLabel = newVal.filter((o) => o.selected).length + ' selected'
      }
    },
    individualSelection (newVal) {
      if (newVal) {
        this.selectedLabel = this.filteredPassengers.filter((o) => o.selected).length + ' selected'
      } else {
        this.selectedLabel = this.filteredPassengers.length ? this.filteredPassengers.length : 'None selected'
      }
    }
  },
  mounted () {
    this.loadFromQueryParams()
    this.filterPassengers()

    this.keydownFunc = (e) => {
      this.shiftKeyPressed = e.key === 'Shift'
    }

    this.keyupFunc = (e) => {
      if (e.key === 'Shift') {
        this.shiftKeyPressed = false
      }
    }

    window.addEventListener('keydown', this.keydownFunc)
    window.addEventListener('keyup', this.keyupFunc)
  },
  methods: {
    toggleAllCheckboxes (checked) {
      this.filteredPassengers = this.filteredPassengers.map((o) => {
        o.selected = checked
        return o
      })
    },
    passengerChecked (passenger, index, checkedStatus) {
      if (this.shiftKeyPressed) {
        const firstCheckedIndex = this.filteredPassengers.findIndex((o) => o.selected) || 0

        this.filteredPassengers = this.filteredPassengers.map((o, i) => {
          o.selected = Boolean(i >= firstCheckedIndex && i <= index)
          return o
        })
      } else if (checkedStatus !== undefined) {
        passenger.selected = checkedStatus
        this.filteredPassengers[index] = passenger
      }

      this.filteredPassengers = this.filteredPassengers.slice()
    },
    loadFromQueryParams () {
      if (this.$route.query.dc) {
        this.dateCategory = ['dateCreated', 'dateUpdated'].find((o) => o.toLowerCase() === this.$route.query.dc.toLowerCase())
      }
      if (this.$route.query.df) {
        this.dateFrom = this.$route.query.df
      }
      if (this.$route.query.dt) {
        this.dateTo = this.$route.query.dt
      }
      if (this.$route.query.cn) {
        this.selectedCountry = this.$route.query.cn.toUpperCase()
      }
      if (this.$route.query.kw) {
        this.searchKeywords = Array.isArray(this.$route.query.kw) ? this.$route.query.kw.join(' ') : this.$route.query.kw
      }
      if (this.$route.query.bfo) {
        this.balanceFilterOperator = this.$route.query.bfo
      }
      if (this.$route.query.bfv) {
        this.balanceFilterValue = this.$route.query.bfv
      }
      if (this.$route.query.tfo) {
        this.ticketsFilterOperator = this.$route.query.tfo
      }
      if (this.$route.query.tfv) {
        this.ticketsFilterValue = this.$route.query.tfv
      }
      if (this.$route.query.nt) {
        this.daysOfNoTripsFilterValue = this.$route.query.nt
      }
      if (this.$route.query.p) {
        this.pagination.page = Number(this.$route.query.p)
      }
      if (this.$route.query.l) {
        this.pagination.rowsPerPage = Number(this.$route.query.l)
      }
      if (this.$route.query.o) {
        this.order = this.$route.query.o
      }
    },
    updateQueryParams () {
      const paramsObj = {
        kw: this.searchKeywords && this.searchKeywords.split(' ').map((o) => o.trim()),
        dc: this.dateCategory,
        df: this.dateFrom,
        dt: this.dateTo,
        cn: this.selectedCountry,
        bfo: this.balanceFilterValue && this.balanceFilterOperator,
        bfv: this.balanceFilterValue,
        tfo: this.ticketsFilterValue && this.ticketsFilterOperator,
        tfv: this.ticketsFilterValue,
        nt: this.daysOfNoTripsFilterValue,
        p: this.pagination.page,
        l: this.pagination.rowsPerPage,
        o: this.order
      }

      this.lastParams = updateQueryParams({ paramsObj, route: this.$route, lastParams: this.lastParams })
    },
    updateQueryParamsAndFilterPassengers (resetPagination) {
      if (resetPagination) {
        this.pagination.page = 1
      }

      this.updateQueryParams()
      this.filterPassengers()
    },
    isDataEdited () {
      return this.smsContent || (this.filteredPassengers || []).some((o) => (o.selected)).length
    },
    async executeByEnterKeyboard (keyboardEvent, callback) {
      if (keyboardEvent?.key === 'Enter') {
        callback()
      }
    },
    loadExtraFilters () {
      this.extraFiltersModal = true
    },
    async sendBulkSMS () {
      try {
        if (!this.smsContent) {
          return alert('SMS content is required')
        }

        if ((this.individualSelection && !this.filteredPassengers.some((o) => o.selected)) || !this.filteredPassengers.length) {
          return alert('No passengers selected or filtered')
        }

        if (confirm(`Confirm sending this SMS to ${this.selectedLabel} passengers`)) {
          this.sendingNotification = true

          const filters = this.individualSelection ? {} : {
            keywords: this.searchKeywords,
            dateCategory: this.dateCategory,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            countryId: this.selectedCountry,
            walletBalance: this.balanceFilterOperator && this.balanceFilterValue ? {
              operator: this.balanceFilterOperator,
              value: this.balanceFilterValue
            } : null,
            ticketsPurchased: this.ticketsFilterOperator && this.ticketsFilterValue ? {
              operator: this.ticketsFilterOperator,
              value: this.ticketsFilterValue
            } : null,
            daysSinceLastTrip: this.daysOfNoTripsFilterValue
          }

          await sendPassengersBulkNotification({
            passengerProfileIds: this.individualSelection ? this.filteredPassengers.filter((o) => o.selected).map((o) => o.id) : undefined,
            smsContent: this.smsContent,
            ...filters
          })

          addAlert({ message: 'Successfully sent SMS', type: 'success' })
        }
      } catch (err) {
        console.error(err)
        addAlert({ message: err, type: 'error' })
      } finally {
        this.sendingNotification = false
      }
    },
    async filterPassengers () {
      try {
        this.apiInProgress = true

        const params = {
          keywords: this.searchKeywords,
          dateCategory: this.dateCategory,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          countryId: this.selectedCountry,
          walletBalance: this.balanceFilterOperator && this.balanceFilterValue ? {
            operator: this.balanceFilterOperator,
            value: this.balanceFilterValue
          } : null,
          ticketsPurchased: this.ticketsFilterOperator && this.ticketsFilterValue ? {
            operator: this.ticketsFilterOperator,
            value: this.ticketsFilterValue
          } : null,
          daysSinceLastTrip: this.daysOfNoTripsFilterValue,
          order: this.order
        }

        const passengersPromise = findBulkPassengers({
          ...params,
          limit: this.pagination.rowsPerPage,
          offset: (this.pagination.page - 1) * this.pagination.rowsPerPage
        })

        const passengersCountPromise = findTotalBulkPassengers(params)
        const [passengers, passengersCount] = await Promise.all([passengersPromise, passengersCountPromise])

        this.filteredPassengers = passengers.map((o) => ({
          ...o,
          selected: false,
          phoneLabel: maskPhoneNumber(o.passengerIdentifiers[0].phoneNumber, this.$store.getters.phoneNumberCountryCodes),
          balanceLabel: formatCurrency(o.balance),
          dateCreatedLabel: `${formatDate(o.dateCreated)} @ ${formatTime(o.dateCreated)}`,
          dateUpdatedLabel: `${formatDate(o.dateUpdated || o.dateCreated)} @ ${formatTime(o.dateUpdated || o.dateCreated)}`
        }))

        this.selectedLabel = passengersCount?.count
        this.pagination.pageTotal = this.pagination.rowsPerPage * (this.pagination.page - 1) + passengers.length
        this.pagination.totalItems = passengersCount?.count
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    window.removeEventListener('keydown', this.keydownFunc)
    window.removeEventListener('keyup', this.keyupFunc)

    if (!this.isDataEdited() || confirm('Discard changes made?')) {
      _cachedComponentData = null
      next()
    } else {
      _cachedComponentData = {
        smsContent: this.smsContent,
        filteredPassengers: this.filteredPassengers,
        searchKeywords: this.searchKeywords,
        dateCategory: this.dateCategory,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        selectedCountry: this.selectedCountry,
        balanceFilterOperator: this.balanceFilterOperator,
        balanceFilterValue: this.balanceFilterValue,
        ticketsFilterOperator: this.ticketsFilterOperator,
        ticketsFilterValue: this.ticketsFilterValue,
        daysOfNoTripsFilterValue: this.daysOfNoTripsFilterValue
      }

      next(false)
    }
  }
}
