<template>
  <div :class="name">
    <h1 class="text-xs-center">Passenger Bulk Notifications</h1>
    <br />
    <v-layout row wrap>
      <v-flex sm4 pa-2>
        <v-text-field
          solo
          clearable
          hide-details
          v-model="searchKeywords"
          append-icon="fal fa-search"
          :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
          :disabled="apiInProgress"
          type="search"
          placeholder="Search by phone number"
          @keydown="executeByEnterKeyboard($event, () => updateQueryParamsAndFilterPassengers(true))"
          @click:append="updateQueryParamsAndFilterPassengers(true)"
        />
      </v-flex>
      <v-flex sm2 pa-2>
        <v-select
          :items="countries"
          dense
          hide-details
          style="width: 100%"
          solo
          clearable
          v-model="selectedCountry"
          @change="updateQueryParamsAndFilterPassengers(true)"
          placeholder="Country phone"
          :menu-props="{ offsetY: true }"
        />
      </v-flex>
      <v-flex sm6 pa-2>
        <div class="pointer d-flex row justify-start" @click="loadExtraFilters" v-if="extraFiltersTags.length">
          <div class="flex xs2">
            <v-btn fab small><v-icon>fa fa-filter</v-icon></v-btn>
          </div>
          <div>
            <v-chip
              v-for="(item, index) in extraFiltersTags"
              :key="index"
              :close="false"
              small
              class="ma-1">
              {{item}}
            </v-chip>
          </div>
        </div>
        <v-btn @click="loadExtraFilters" block v-if="!extraFiltersTags.length"><v-icon small left>fa fa-filter</v-icon> Extra Filters</v-btn>
      </v-flex>
    </v-layout>
    <div style="height: 5px">
      <v-progress-linear :active="apiInProgress" indeterminate :height="5" />
    </div>
    <v-layout row wrap mb-1>
      <v-flex md8 xs12 pb-3 pl-3>
        <v-layout row wrap mb-1>
          <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
            Select Passengers Individually
          </v-flex>
          <v-flex sm8>
            <v-switch
              style="margin-top: 0"
              v-model="individualSelection"
              label="Enable"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-4>
          <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
            Selected Passengers
          </v-flex>
          <v-flex sm8>
            <em>{{selectedLabel}}</em>
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1>
          <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
            SMS Text
          </v-flex>
          <v-flex sm8>
            <v-textarea
              outline
              v-model="smsContent"
              counter="150"
              placeholder="Enter content to send to passengers"
              :rules="[() => smsContent.length > 150 ? 'SMS content may be spread over several texts' : true]"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout row wrap mb-1>
          <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
          </v-flex>
          <v-flex sm8>
            <v-btn color="primary" @click="sendBulkSMS" :loading="sendingNotification" :disabled="sendingNotification">Send Bulk Text</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex pb-3 xs12 md4 pl-3 pr-3>
        <h3 class="text-xs-center mb-3">Passengers</h3>
        <div class="d-flex justify-center align-center" style="flex-direction: column">
          <div class="d-flex justify-center align-center mb-3" v-if="pagination.totalItems">
            <div class="text-xs-right mr-2">Total: {{pagination.pageTotal}} of {{pagination.totalItems}}</div>
            <div>
              <v-select
                style="max-width: 70px;"
                :items="[{ text: '10', value: 10 }, { text: '20', value: 20 }, { text: '50', value: 50 }, { text: '100', value: 100 }]"
                dense
                hide-details
                solo
                v-model="pagination.rowsPerPage"
                placeholder="Items per page"
                @change="updateQueryParamsAndFilterPassengers(true)"
                :menu-props="{ offsetY: true }"
              />
            </div>
          </div>
          <v-pagination
            v-model="pagination.page"
            @input="updateQueryParamsAndFilterPassengers()"
            @next="updateQueryParamsAndFilterPassengers()"
            @previous="updateQueryParamsAndFilterPassengers()"
            total-visible="6"
            :length="Math.ceil(pagination.totalItems / pagination.rowsPerPage)"
          />
        </div>
        <v-card>
          <v-list three-line>
            <v-list-tile v-if="!filteredPassengers.length">
              <v-list-tile-content>
                <v-list-tile-title>No passengers filtered</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <template v-if="filteredPassengers.length">
              <v-list-tile>
                <v-list-tile-action v-if="individualSelection">
                  <v-checkbox v-if="individualSelection" :indeterminate="filteredPassengers && filteredPassengers.some((o) => o.selected) && !filteredPassengers.every((o) => o.selected)" @change="toggleAllCheckboxes" />
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-select
                    :items="orderItems"
                    dense
                    hide-details
                    outline
                    v-model="order"
                    label="Order By"
                    @change="updateQueryParamsAndFilterPassengers()"
                    :menu-props="{ offsetY: true }"
                  />
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile :key="passenger.id" v-for="(passenger, index) in filteredPassengers">
                <v-list-tile-action v-if="individualSelection">
                  <v-checkbox v-model="passenger.selected" @change="(checked) => passengerChecked(passenger, index, checked)" />
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{passenger.phoneLabel}} <small>Balance: {{passenger.balanceLabel}}</small></v-list-tile-title>
                  <v-list-tile-sub-title>Updated: {{passenger.dateUpdatedLabel}}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>Created: {{passenger.dateCreatedLabel}}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-card>
        <div class="d-flex justify-center align-center" style="flex-direction: column">
          <div class="mt-3 text-xs-center" v-if="pagination.totalItems">Total: {{pagination.pageTotal}} of {{pagination.totalItems}}</div>
          <v-pagination
            v-model="pagination.page"
            @input="updateQueryParamsAndFilterPassengers()"
            @next="updateQueryParamsAndFilterPassengers()"
            @previous="updateQueryParamsAndFilterPassengers()"
            total-visible="6"
            :length="Math.ceil(pagination.totalItems / pagination.rowsPerPage)"
          />
        </div>
      </v-flex>
    </v-layout>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        max-width="600"
        v-model="extraFiltersModal">
      <v-card>
        <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
          Extra Filters
        </v-card-title>
        <v-card-text>
          <v-layout mb-4>
            <v-flex sm4 xs12>
              <v-select
                :items="[{ text: 'Date Created', value: 'dateCreated' }, { text: 'Date Updated', value: 'dateUpdated' }]"
                dense
                hide-details
                style="width: 100%"
                solo
                v-model="dateCategory"
                placeholder="Date type"
                :menu-props="{ offsetY: true }"
              />
            </v-flex>
            <v-flex sm4 xs12 pl-2>
              <v-menu
                  v-model="dateFromMenu"
                  :close-on-content-click="false"
                  offset-y
                  full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      prepend-icon="fal fa-calendar"
                      :value="computedDateFromFormatted"
                      @click:clear="dateFrom = null"
                      clearable
                      label="From"
                      readonly
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    no-title
                    :max="computedDateFromMax"
                    v-model="dateFrom"
                    @change="dateFromMenu = false"
                />
              </v-menu>
            </v-flex>
            <v-flex sm4 xs12 pl-2>
              <v-menu
                  v-model="dateToMenu"
                  :close-on-content-click="false"
                  offset-y
                  full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      prepend-icon="fal fa-calendar"
                      :value="computedDateToFormatted"
                      @click:clear="dateTo = null"
                      clearable
                      label="To"
                      readonly
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    no-title
                    :min="computedDateToMin"
                    v-model="dateTo"
                    @change="dateToMenu = false"
                />
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row wrap mb-4>
            <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
              Wallet balance
            </v-flex>
            <v-flex sm8 class="d-flex row">
              <v-select
                :items="[{ text: 'Equal to', value: '=' }, { text: 'Less than', value: '<' }, { text: 'Greater than', value: '>' }]"
                dense
                hide-details
                solo
                v-model="balanceFilterOperator"
                :menu-props="{ offsetY: true }"
              />
              <v-text-field
                class="ml-2"
                placeholder="Enter number"
                clearable
                v-model="balanceFilterValue"
                type="number"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap mb-4>
            <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
              Tickets purchased
            </v-flex>
            <v-flex sm8 class="d-flex row">
              <v-select
                :items="[{ text: 'Equal to', value: '=' }, { text: 'Less than', value: '<' }, { text: 'Greater than', value: '>' }]"
                dense
                hide-details
                solo
                v-model="ticketsFilterOperator"
                :menu-props="{ offsetY: true }"
              />
              <v-text-field
                :rules="[v => v < 0 ? 'Please enter a number 0 or higher' : true]"
                class="ml-2"
                placeholder="Enter number"
                min="0"
                clearable
                v-model="ticketsFilterValue"
                type="number"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap mb-4>
            <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
              Days since last trip
            </v-flex>
            <v-flex sm8 class="d-flex row">
              <v-text-field
                :rules="[v => v < 0 ? 'Please enter a number 0 or higher' : true]"
                class="ml-2"
                placeholder="Enter number"
                min="0"
                clearable
                v-model="daysOfNoTripsFilterValue"
                type="number"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="warning" flat @click="extraFiltersModal = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="updateQueryParamsAndFilterPassengers(); extraFiltersModal = false">Filter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
